import React from "react";
import PortfolioPage from "../../index";
import { digitalDeskLogos } from "../../data";

export default function DesignDeskPage() {
  return (
    <div>
      <PortfolioPage heading="Design Desk" data={digitalDeskLogos} />
    </div>
  );
}
